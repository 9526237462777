import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { fromSearchFore } from '@/const/publicData/record'

export const object = [
    ...fromSearchFore,
{
    type: 'select',
    name: t('currencySet.text12'),
    prop: 'status',
    postData: [{ label: t('currencySet.name11_1'), value: 1 },
    { label: t('currencySet.name11_2'), value: 0 }]
},{
    type: 'datetime',
    name: t('currencySet.text13'),
    prop: 'createTime'
}]