<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <avue-crud
            ref="crud" :option="tableOption" :data="list" :page="page"
            v-model="form" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <!-- <template #chain-header>
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="$t('currencySet.name1')"
                    placement="top-start"
                >
                {{$t('currencySet.name1')}}
            </el-tooltip>
            </template> -->
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="viewOpen('add')">{{$t('button.add')}} </el-button>
                <el-button class="filter-item"  @click="bindExamine"  v-if='isShow("withdraw_coin_exemption_page")'>审核设置 </el-button>
            </template>   
            <template #caozuo='scope'>
                <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"    @click="viewOpen('update',scope.row)">{{$t('button.update')}} </el-button>
                <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"    @click="delFun(scope.row.tenantCoinId)">{{$t('button.del')}} </el-button>
                <el-button v-if="roleManager_btn_edit" text type="primary" @click="copyFun(scope.row)"
                    size="small"   >{{$t('button.writes')}} </el-button>
                <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"   >{{$t('button.more')}} </el-button>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
        </avue-crud>
        <addView :dialogParmStyle='dialogParmStyle' ref='addView_' @successFun='successFun'/>
        <copyView ref='copyShow_' />
        <examine ref="examine_" />
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,getCurrentInstance } from 'vue'
    import { tableOption1 } from "@/const/crud/cryptocurrency/currencySet";
    import apiList from '@/const/apiList'
    import addView from './add.vue'
    import copyView from './copy.vue'
    import examine from './examine.vue'
    import { ElMessageBox,ElNotification } from 'element-plus'
    import fromSearch from '@/components/fromSearch.vue'
    import { object } from '@/const/from/cryptocurrency/walletSettings/currencySet'
    const object_ = ref(object);
    const { $t } = getCurrentInstance().proxy;
    import { turnTimeFun } from '@/utils/util.js'
    

    
    const list = ref([])
    const form = ref({})
    const copyShow_ = ref(null)
    const addView_ = ref(null)
    const examine_ = ref(null)
    const listLoading = ref(false)
    const tableOption = ref({});
    tableOption.value = tableOption1()
    const page = reactive({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
    })
    const roleManager_btn_edit = ref(true)
    const roleManager_btn_add = ref(true)
    const tenantArray = ref([])
    const appArray = ref([])
    const chainList = ref([])
    // const ruleFormRef =ref(null)
    import { userStore } from '@/store/user.js'
    const getButtonArray_ = ref(userStore());
    const isShow = (str)=>{
        if(getButtonArray_.value && getButtonArray_.value.getButtonArray && Array.isArray(getButtonArray_.value.getButtonArray)){
            return getButtonArray_.value.getButtonArray.includes(str)
        }else{
            return false
        }
    }

   
    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data})
    apiList.getChainList((res)=>{chainList.value = res})

    const bindExamine = ()=>{
        examine_.value.init()
    }
    const copyFun = (e)=>{
        copyShow_.value.setData1({coinId:e.coinId,tenantCoinId:e.tenantCoinId})
    }
    const dialogParmStyle = ref('add')
    const successFun = ()=>{
        page.currentPage = 1;
        getList(1)
    }
    const viewOpen = (str,scope)=>{
        // dialogParm.show = true
        dialogParmStyle.value = str
        addView_.value.setData(tenantArray,appArray,scope)
    }
    const getList = (e)=>{
        let obj_ = {
            current: page.currentPage,
            size: page.pageSize
        }
        if(e && e !== 1){
            obj_ = Object.assign({}, obj_, e)
        }
        listLoading.value = true
        Api_.getCurrencySetList(obj_).then(res=>{
            if(res.code === 0){
                listLoading.value = false
                list.value = res.data.records
                page.total = res.data.total
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
    }
    const delFun = (roleId)=>{
        ElMessageBox.confirm($t('alert.selEnter'), $t('tyle_.tits'), {
            confirmButtonText: $t('button.enter'),
            cancelButtonText: $t('button.close'),
            type: 'warning',
        }).then(()=>{
            Api_.delCurrencyShopObj(roleId).then(res=>{
                if(res.code === 0 ){
                    getList(1)
                    ElNotification({
                        message: $t('alert.succDel'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg?res.msg:$t('alert.delText'),
                        type: 'error',
                    })
                }
            })
        })
    }
    const sizeChange = (pageSize)=>{ 
        page.pageSize = pageSize;
        getList(1)
    }
    const currentChange = (currentPage)=>{ 
        page.currentPage = currentPage;
        getList(1)
    }
    getList(1)
</script>

